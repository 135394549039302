import { Button } from "@/components";
import { useCompanyStore } from "@/store/company";
import { useUserStore } from "@/store/user";

export const ErrorComponent = ({ showSuportMessage, handleReload }) => {
  const companySelected = useCompanyStore((state) => state.company);
  const userSelected = useUserStore((state) => state.user);

  return (
    <>
      <div className="lg:px-24 lg:py-24 md:py-20 md:px-44 px-4 items-center flex justify-center flex-col-reverse lg:flex-row md:gap-28">
        <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
          <div className="relative">
            <div className="absolute">
              <div className="gap-y-4">
                <h1 className="my-2 text-gray-800 font-bold text-2xl">
                  Ocorreu uma inconsistência técnica nesta página!
                </h1>
                {showSuportMessage && (
                  <p className="leading-7 text-gray-700 text-sm">
                    Caso o erro esteja persistindo, por favor{" "}
                    <a
                      target="_blank"
                      href={`https://api.whatsapp.com/send?phone=554691379545&text=Ol%C3%A1,%20sou%20${userSelected?.first_name
                        .split(" ")
                        .join("%20")}%20do%20${companySelected.nome_fantasia
                        .split(" ")
                        .join("%20")}%20e%20um%20erro%20esta%20ocorrendo!`}
                      className="font-medium text-primary underline underline-offset-4"
                    >
                      entre em contato com o nosso suporte clicando aqui!
                    </a>
                  </p>
                )}

                <div className="flex gap-8 max-sm:gap-2 max-md:flex-col py-4">
                  <Button
                    variant="primary"
                    className="p-6 w-full"
                    onClick={() => handleReload()}
                  >
                    Recarregar página
                  </Button>
                  <a href="/" className="w-full">
                    <Button
                      variant="primary"
                      className="p-6 w-full"
                      onClick={() => handleReload()}
                    >
                      Ir para a página inicial
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <img src="https://i.ibb.co/ck1SGFJ/Group.png" />
        </div>
      </div>
    </>
  );
};
