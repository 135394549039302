import { ForwardedRef, forwardRef, useCallback, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { LicenseManager } from "ag-grid-enterprise";

import { AG_GRID_LOCALE_BR } from "./locale";
import { GoalInformation } from "@/types/reports/sales/goalTracking";
import { ColumnConfig } from "@/types/agGrid";
import { SaleInformation } from "@/types/reports/salesAnalysis";

LicenseManager.setLicenseKey(import.meta.env.VITE_AGGRID_TOKEN);

interface AgGridBaseProps {
  groupDefaultExpanded?: number;
  filteredReportData?: GoalInformation[] | SaleInformation[];
  columnDefs: ColumnConfig[];
  groupColumnDefs?: ColumnConfig;
  isPivotMode?: boolean;
  handleReportData?: (columns: []) => void;
  handleChangeFilter?: () => void;
  onRowDoubleClicked?: (opts: any) => void;
  onCellDoubleClicked?: (opts: any) => void;
  onCellValueChanged?: (opts: any) => void;
  groupAllowUnbalanced?: boolean;
  gridOptions?: any;
}

export const AgGridBase = forwardRef(
  (
    {
      filteredReportData,
      columnDefs,
      gridOptions,
      groupAllowUnbalanced,
      isPivotMode,
      handleReportData,
      handleChangeFilter,
      groupDefaultExpanded,
      groupColumnDefs,
      onRowDoubleClicked,
      onCellDoubleClicked,
      onCellValueChanged,
    }: AgGridBaseProps,
    ref: ForwardedRef<AgGridReact>,
  ) => {
    const columnDefinitions = useMemo(() => {
      return {
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true,
        enableRowGroup: true,
      };
    }, []);

    const sidebarDefinitions = useMemo(() => {
      return {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
            },
          },
        ],
      };
    }, []);

    const onSelectionChanged = useCallback(() => {
      if (ref && "current" in ref && ref.current) {
        const selectedRows = ref.current!.api.getSelectedRows();

        handleReportData && handleReportData(selectedRows as []);
      }
    }, [ref, handleReportData]);

    return (
      <AgGridReact
        ref={ref}
        className={"ag-theme-quartz font-light"}
        rowHeight={30}
        rowData={filteredReportData}
        columnDefs={columnDefs}
        defaultColDef={columnDefinitions}
        onFilterChanged={handleChangeFilter}
        localeText={AG_GRID_LOCALE_BR}
        headerHeight={40}
        rowGroupPanelShow="always"
        sideBar={sidebarDefinitions}
        rowSelection="multiple"
        rowMultiSelectWithClick={true}
        autoGroupColumnDef={{ ...groupColumnDefs }}
        quickFilterText=""
        onSelectionChanged={onSelectionChanged}
        groupDefaultExpanded={groupDefaultExpanded}
        pivotMode={isPivotMode}
        suppressAggFuncInHeader={true}
        groupAllowUnbalanced={groupAllowUnbalanced}
        onRowDoubleClicked={onRowDoubleClicked}
        onCellDoubleClicked={onCellDoubleClicked}
        onCellValueChanged={onCellValueChanged}
        autoSizeStrategy={{
          type:"fitCellContents"
        }}
        gridOptions={{
          grandTotalRow: "bottom",
          groupSuppressBlankHeader: true,
          tooltipShowDelay: 50,
          autoSizeStrategy: {
            type: 'fitCellContents'
          },
          statusBar: {
            statusPanels: [
              {
                statusPanel: "agTotalRowCountComponent",
                align: "left",
              },
              {
                statusPanel: "agFilteredRowCountComponent",
              },
              {
                statusPanel: "agSelectedRowCountComponent",
              },
              {
                statusPanel: "agAggregationComponent",
              },
            ],
          },
          ...gridOptions,
        }}
      />
    );
  },
);
