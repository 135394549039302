import { HelpCircle, LayoutGrid, Logs, MapPinCheck } from "lucide-react";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "../ui";
import { Link } from "react-router-dom";
import { useUserStore } from "@/store/user";
import { UserGroups } from "@/constants/user";

const complementaryApps = [
  {
    name: "Logs",
    icon: Logs,
    link: "/logs",
    userGroupsAllowed: [UserGroups.admin, UserGroups.supervisor],
  },
  {
    name: "Central de Ajuda",
    icon: HelpCircle,
    link: "/help-center",
    userGroupsAllowed: [
      UserGroups.normal,
      UserGroups.admin,
      UserGroups.supervisor,
    ],
  },
];

export const HeaderComplementaryApps = () => {
  const userSelected = useUserStore((store) => store.user);

  return (
    <NavigationMenu>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger className="ml-3 mr-1" hideArrow>
            <LayoutGrid className="h-6 w-6" />
          </NavigationMenuTrigger>

          <NavigationMenuContent>
            <ul className="p-0">
              {userSelected &&
                userSelected?.email.includes("@flowanalytics.com.br") && (
                  <li>
                    <NavigationMenuLink asChild>
                      <Link to="/check-in">
                        <div className="flex flex-col p-3 w-32 items-center justify-center hover:bg-stone-100 border">
                          <MapPinCheck className="h-6 w-6 text-gray-600" />
                          <span className="text-xs text-center text-gray-600 font-medium mt-2">
                            Check-in
                          </span>
                        </div>
                      </Link>
                    </NavigationMenuLink>
                  </li>
                )}

              {userSelected &&
                complementaryApps
                  .filter((app) =>
                    app.userGroupsAllowed.length > 0
                      ? app.userGroupsAllowed.includes(userSelected?.group)
                      : true
                  )
                  .map((app) => (
                    <li key={app.name}>
                      <NavigationMenuLink asChild>
                        <Link to={app.link}>
                          <div className="flex flex-col p-3 w-32 items-center justify-center hover:bg-stone-100 border">
                            <app.icon className="h-6 w-6 text-gray-600" />
                            <span className="text-xs text-center text-gray-600 font-medium mt-2">
                              {app.name}
                            </span>
                          </div>
                        </Link>
                      </NavigationMenuLink>
                    </li>
                  ))}
            </ul>
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
};
