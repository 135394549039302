import { useReportGroupsStore } from '@/store/reportPermissions';
import { useMemo } from 'react';

export function useHasReport(pathname: string) {
  const reportGroups = useReportGroupsStore((store) => store.reportGroups);
  
  const hasReport = useMemo(() => {
    if (!reportGroups || reportGroups.length === 0) {
      return true;
    }
    
    return reportGroups.some((group) => {
      const groupReports = group.reportsApi.concat(group.reports);

      if (groupReports.length > 0) {
        return groupReports.some((report) => {
          return (
            pathname.includes(report.report_type) ||
            pathname.includes(report.report_id) ||
            pathname.includes('/administrative') ||
            pathname.includes('/logs') ||
            pathname.includes('/check-in') ||
            pathname.includes('/help-center')
          );
        });
      }
      return false;
    });
  }, [pathname, reportGroups]);

  return hasReport;
}
